
  .tree ul {
    margin: 0 0 0 290px;
    padding: 0;
    list-style: none;
    color: #369;
    position: relative;
  }
  .tree:before, .tree ul:before {
    content: "";
    display: block;
    width: 0px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -300px;
    border-left: 1px solid;
    background: white;
   
  }

 
  .tree li {
    font-weight: bold;
    line-height: 3em;
    /* margin: 0; */
    padding: 1.5em 0 0 2em;
    position: relative;
    /* z-index: -1; */
  }
  .tree li:before {
    border-top: 1px solid gray;
    content: "";
    display: block;
    height: 100%;
    left: -300px;
    margin-top: 1em;
    position: absolute;
    top: 2.5em;
    width: 320px;
  
  }
  .tree li:last-child:before {
    background: white;
    bottom: 0;
    height: auto;
    top: 1.5em;
  }
  .tree > li:before {
    border-top: none;
  }
  .tree li div {
    border-radius: 5px;
    border: 1px solid #afafaf;
    margin: 0;
    max-width: 300px;
    min-width: 300px;
    padding: 0.25em 0.5em;
    background: white;
    box-shadow: 1px 1px 4px #8F949A;
    color: #369;
    font-weight: bold;
  }
  ul.tree {
    margin-left: 0px;
  }
  ul.tree:before {
    border-left: none;
  }
  .sticky {
    
    position: sticky;
    top: 0px;
    width: 100%;
  }
  