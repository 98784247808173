div {
  --color-header-background: white;
  --color-timeline-text: rgba(17, 17, 17, 1);
}

.rbc-time-view {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.event {
  background-color: transparent;
}

div {
  --color-header-background: white;
  --color-timeline-text: rgba(17, 17, 17, 1);
}

.rbc-time-slot {
  min-height: 40px;
}

.rbc-header {
  display: flex;
  flex-direction: column;
  justify-items: center;
  min-height: 32px !important;
  max-height: 32px;
  background: #F9FAFB;
}

.rbc-event {
  display: flex;
  flex-direction: column;
}

.event {
  background-color: transparent;
}

.rbc-event {
  left: 0 !important;
  width: 100% !important;
}
.rbc-row .rbc-time-header-cell .rbc-header a span {
  display: flex !important;
  flex-direction: column !important;
}

.rbc-label .rbc-time-header-gutter {
  min-width: 70px !important;
}

.rbc-event {
  left: 0 !important;
  width: 85% !important;
}

.rbc-timeslot-group .rbc-time-slot {
  min-width: 70px !important;
}

.rbc-time-slot .rbc-label {
  font-size: 12px;
  position: relative !important;
  bottom: 0px !important;
  background-color: white;
}

.rbc-today {
  background-color: #F9F5FF !important;
}

.rbc-button-link span {
    color: #182230;
    font-weight: 600;
}

.styles-module_header__10uIZ {
  will-change: transform;
  z-index: var(--z-index-header);
  background: var(--color-header-background);
  box-shadow:
    0 1px 5px var(--color-header-shadow-1),
    0 1px 10px var(--color-header-shadow-2);
  background-color: #8e43e7;
}
.rbc-button-link {
  margin-top: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #667085;
}
.rbc-label {
  font-size: 12px;
  font-weight: 500;
  color: #667085;
}
