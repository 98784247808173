@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}
@font-face {
  font-family: "Codec Pro Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Codec Pro Regular"), url("/src/assets/fonts/Codec-Cold-Regular-trial.ttf") format("woff");
}
@font-face {
  font-family: "Codec Pro Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Codec Pro Regular"), url("/src/assets/fonts/Codec-Cold-Bold-trial.ttf") format("woff");
}